import { StyledFormGroup } from '@monorepo/shared/componentsV2/styled/form';
import { useDataset } from '@monorepo/shared/hooks/workflows/useDataset';
import { WorkflowResponse } from 'mapistry-shared';
import React from 'react';
import { useField } from 'react-final-form';
import { DatasetInfo } from '../../shared/DatasetPicker/DatasetInfo';
import { findStepInputDataset } from '../stepUtils';
import { JoinToDatasetPicker } from './JoinToDatasetPicker';
import { StepConditions } from './StepConditions';
import { StyledH3, StyledJoinToText, StyledSubheading } from './styled';

interface EditJoinStepFormProps {
  workflow: WorkflowResponse;
  stepId?: string;
}

export function EditJoinStepForm({ workflow, stepId }: EditJoinStepFormProps) {
  const {
    input: { value: selectedJoinToDatasetId },
  } = useField<string>('joinToDatasetId');

  const { dataset: joinToDataset } = useDataset({
    organizationId: workflow.organizationId,
    datasetId: selectedJoinToDatasetId,
  });

  const stepInputDataset = findStepInputDataset(workflow, stepId);

  return (
    <>
      <StyledH3>Data Sources</StyledH3>
      <StyledSubheading>Data Source 1</StyledSubheading>
      <DatasetInfo dataset={stepInputDataset} isLocked />

      <StyledJoinToText>join to</StyledJoinToText>

      <StyledFormGroup>
        <JoinToDatasetPicker
          organizationId={workflow.organizationId}
          stepInputDataset={stepInputDataset}
          workflowOutputDataset={workflow.outputDataset}
        />
      </StyledFormGroup>

      <StepConditions
        joinToDataset={joinToDataset}
        stepInputDataset={stepInputDataset}
      />
    </>
  );
}
