import { fieldTypeOptions } from '@monorepo/shared/componentsV2/fieldDataType/fieldTypeOptions';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import {
  FormulaTokenList,
  FormulaTokenListItem,
  FormulaTokenListItemReactElement,
  FormulaTokenListItemProp,
} from '@monorepo/shared/componentsV2/formula';
import { useLogInfo } from '@monorepo/shared/hooks/logs/useLogInfo';
import {
  FieldDataType,
  FieldResponse,
  FormulaFieldResponse,
  MathUnit,
  NumericFieldResponse,
  ResourceFieldResponse,
} from 'mapistry-shared';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom-v5-compat';

const isNumericField = (x: FieldResponse): x is NumericFieldResponse =>
  x.type === FieldDataType.NUMERIC;

type LogFormulaTokenListProps = {
  currentColumnName?: FieldResponse['columnName'];
};

export function LogFormulaTokenList({
  currentColumnName = '',
}: LogFormulaTokenListProps) {
  const { logId, organizationId } = useParams();
  const { logInfo, isLoading } = useLogInfo({ logId, organizationId });

  const logFields = useMemo(
    () =>
      (logInfo?.log.fields || []).filter(
        (field) =>
          field.type !== FieldDataType.FORMULA &&
          field.type !== FieldDataType.RESOURCE &&
          !!logInfo?.sampleFormulaTerms[field.columnName],
      ),
    [logInfo?.log.fields, logInfo?.sampleFormulaTerms],
  );
  const resourceFields = useMemo(
    () =>
      (logInfo?.log.fields || []).filter<ResourceFieldResponse>(
        (field): field is ResourceFieldResponse =>
          field.type === FieldDataType.RESOURCE &&
          !!logInfo?.sampleFormulaTerms[field.columnName],
      ),
    [logInfo?.log.fields, logInfo?.sampleFormulaTerms],
  );
  const formulaFields = useMemo(
    () =>
      (logInfo?.log.fields || []).filter<FormulaFieldResponse>(
        (field): field is FormulaFieldResponse =>
          field.type === FieldDataType.FORMULA &&
          !!logInfo?.sampleFormulaTerms[field.columnName] &&
          currentColumnName !== field.columnName,
      ),
    [currentColumnName, logInfo?.log.fields, logInfo?.sampleFormulaTerms],
  );

  const listItems = useMemo<FormulaTokenListItemProp[]>(() => {
    const result: FormulaTokenListItemProp[] = [];

    if (logFields.length) {
      result.push({
        header: 'Log Fields',
        items: logFields.reduce<FormulaTokenListItemReactElement[]>(
          (acc, field) => {
            /* We filtered out the formula fields above. This is for typechecking only */
            if (field.type === FieldDataType.FORMULA) {
              return acc;
            }
            const label =
              isNumericField(field) && field.units
                ? `${field.name} (${MathUnit.getLabel(field.units)})`
                : field.name;
            const item = (
              <FormulaTokenListItem
                key={field.columnName}
                icon={fieldTypeOptions[field.type]?.icon}
                iconAltText={fieldTypeOptions[field.type]?.label}
                name={label}
                token={field.columnName}
              />
            );
            return [...acc, item];
          },
          [],
        ),
      });
    }

    if (resourceFields.length) {
      resourceFields.forEach((field) => {
        const resourceType = logInfo?.resourceTypes[field.resourceTypeId];
        result.push({
          header: `Resource Properties: ${field.name}`,
          items: (resourceType?.properties || []).reduce<
            FormulaTokenListItemReactElement[]
          >((acc, property) => {
            /* Resource type properties can't be formulas. This is for typechecking only */
            if (property.type === FieldDataType.FORMULA) {
              return acc;
            }
            const label =
              isNumericField(property) && !!property.units
                ? `${property.name} (${MathUnit.getLabel(property.units)})`
                : property.name;
            const item = (
              <FormulaTokenListItem
                key={property.columnName}
                icon={fieldTypeOptions[property.type]?.icon}
                iconAltText={fieldTypeOptions[property.type]?.label}
                name={label}
                token={`${field.columnName}.${property.columnName}`}
              />
            );
            return [...acc, item];
          }, []),
        });
      });
    }

    if (formulaFields.length) {
      result.push({
        header: 'Formulas',
        items: formulaFields.map((field) => (
          <FormulaTokenListItem
            key={field.columnName}
            formulaExpression={field.expression}
            name={field.name}
            token={field.columnName}
          />
        )),
      });
    }
    return result;
  }, [formulaFields, logFields, logInfo?.resourceTypes, resourceFields]);

  if (isLoading) {
    return <Loading />;
  }

  return <FormulaTokenList listItems={listItems} />;
}
