import { Breadcrumbs } from '@monorepo/shared/componentsV2/Breadcrumbs';
import { Header } from '@monorepo/shared/componentsV2/Header';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { HorizontalNavigationTabs } from '@monorepo/shared/componentsV2/NavigationTabs/HorizontalNavigationTabs';
import { useLog } from '@monorepo/shared/hooks/logs/useLog';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import {
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import React, { useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';

const Page = styled.div`
  ${verticalLayout}
  ${page}
`;

const StyledTabs = styled(HorizontalNavigationTabs)`
  background-color: ${({ theme }) => theme.colors.blue04};
`;

const StyledHeader = styled(Header)`
  background-color: ${({ theme }) => theme.colors.blue04};
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  padding: 0;
` as typeof Breadcrumbs;

const TabContent = styled.div`
  ${fillHeightAndScrollable}
`;

const tabOptions = [
  {
    href: 'records',
    label: 'Records',
  },
  {
    href: 'views',
    label: 'Views',
  },
  {
    href: 'frequencySettings',
    label: 'Frequency Settings',
  },
];

export interface SingleLogPageOutletContext {
  hideHeader(): void;
  hideTabs(): void;
  showHeader(): void;
  showTabs(): void;
}

export function SingleLogPage() {
  const { project } = useCurrentProject();
  const { logId, organizationId } = useParams();
  const { log, isLoading, error } = useLog({ logId, organizationId });
  const [hideHeader, setHideHeader] = useState(false);
  const [hideTabs, setHideTabs] = useState(false);

  const toLogsHomePage = '..';

  const crumbs = useMemo(
    () => [
      { label: 'All Logs', to: toLogsHomePage },
      { label: log?.name || 'unknown log', to: '.' },
    ],
    [log?.name],
  );

  const navigate = useNavigate();

  const outletContextValue: SingleLogPageOutletContext = useMemo(
    () => ({
      hideHeader: () => setHideHeader(true),
      hideTabs: () => setHideTabs(true),
      showHeader: () => setHideHeader(false),
      showTabs: () => setHideTabs(false),
    }),
    [],
  );

  if (error) {
    // A 404 error very likely means that we switched projects (into a different organization) while looking at
    //  a specific log, so the log ID in our URL is invalid.
    if (error.status === 404) navigate(toLogsHomePage);
    else throw error;
  }

  return isLoading ? (
    <Loading />
  ) : (
    <Page>
      {!hideHeader && (
        <StyledHeader
          title={<StyledBreadcrumbs crumbs={crumbs} />}
          subtitle={project?.name}
          variant="noDropShadow"
        />
      )}
      {!hideTabs && (
        <StyledTabs label={log?.name || 'Log'} options={tabOptions} />
      )}
      <TabContent>
        <Outlet context={outletContextValue} />
      </TabContent>
    </Page>
  );
}
