import { useSingleQueryStep } from '@monorepo/logs/src/contexts/SingleQueryStepContext';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { ConditionalWrapper } from '@monorepo/shared/componentsV2/ConditionalWrapper';
import AddIcon from '@svg/add.svg';
import { MathUnit } from 'mapistry-shared';
import React, { useCallback } from 'react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { LimitCondition } from './LimitCondition';
import { FormValueLimitCondition, LimitType } from './types';
import { getInitialLimitCondition } from './utilities';

const AddButton = styled(Button)`
  width: fit-content;
`;

const Row = styled.div`
  display: flex;
  gap: 1rem;
`;

const rowWrapper = (children: React.ReactNode) => <Row>{children}</Row>;

export function LimitConditions() {
  const { availableColumns, groupedColumns } = useSingleQueryStep();
  const { batch, change, getFieldState, getState } = useForm();

  const getUnitsForColumn = useCallback(
    (columnName?: string) => {
      if (!columnName) {
        return undefined;
      }
      const column = availableColumns.find(
        (col) => col.columnName === columnName,
      );

      return column?.units;
    },
    [availableColumns],
  );

  const { values } = getState();
  const units = getUnitsForColumn(values.columnName);
  const limitType = getFieldState('limitType')?.value;
  const hasGroupedColumns = !!groupedColumns.length;

  return (
    <FieldArray<FormValueLimitCondition, never> name="limitConditions">
      {({ fields: limitConditions }) => {
        batch(() =>
          limitConditions.forEach((name, idx) => {
            const {
              max,
              min,
              units: currentUnits,
            } = values.limitConditions[idx] ?? {};

            // Change units to match the selected column if it isn't a related unit (because the column changed for example)
            if (
              !currentUnits ||
              (units && !MathUnit.areRelated(currentUnits, units))
            ) {
              change(`${name}.units`, units);
            }

            // Clear the min value if the limit type changed to max
            if (limitType === LimitType.max && !!min) {
              change(`${name}.min`, undefined);
            }

            // Clear the max value if the limit type changed to min
            if (limitType === LimitType.min && !!max) {
              change(`${name}.max`, undefined);
            }
          }),
        );
        return (
          <>
            {limitConditions.map((limitConditionName, limitConditionIndex) => (
              <ConditionalWrapper
                condition={hasGroupedColumns}
                key={limitConditionName}
                wrapper={rowWrapper}
              >
                <LimitCondition
                  hideLabels={limitConditionIndex > 0}
                  limitType={limitType || LimitType.max}
                  name={limitConditionName}
                  onDelete={
                    limitConditions.value.length > 1
                      ? () => limitConditions.remove(limitConditionIndex)
                      : undefined
                  }
                  units={units}
                />
              </ConditionalWrapper>
            ))}
            {hasGroupedColumns && (
              <AddButton
                color="primary"
                onClick={() =>
                  limitConditions.push(getInitialLimitCondition(groupedColumns))
                }
                variant="text"
              >
                <AddIcon />
                Add limit to another group by value
              </AddButton>
            )}
          </>
        );
      }}
    </FieldArray>
  );
}
