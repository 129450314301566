import {
  FormControl,
  FormControlLabel as MuiFormControlLabel,
  FormLabel as MuiFormLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { bodyMedium, bodyRegular } from '../styles/text';
import { SelectableOption } from '../types/SelectableOption';
import { ErrorText } from './styled/form';

// label above the options
const FormLabel = styled(MuiFormLabel)`
  ${bodyMedium}
  color: ${({ theme }) => theme.colors.gray333};
  transform: none;

  &.Mui-focused {
    color: ${({ theme }) => theme.colors.gray333};
  }

  &.Mui-error {
    color: ${({ theme }) => theme.colors.darkRed};
  }
` as typeof MuiFormLabel;

// text next to options
const FormControlLabel = styled(MuiFormControlLabel)`
  & .MuiFormControlLabel-label {
    ${bodyRegular}
  }
`;

const Radio = styled(MuiRadio)`
  /* override MUI's coloring of the radio buttons */
  & .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.colors.gray666};
  }

  &.Mui-checked .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.colors.lightBlue};
  }

  /* we don't yet expose the ability to disable a radio button, but this is here for when we do -
     the opacity matches what MUI uses for disabled components */
  &.Mui-disabled .MuiSvgIcon-root {
    opacity: 0.38;
  }
`;

type RadioGroupProps = {
  className?: string;
  error?: boolean;
  errorText?: string;
  label: string;
  name: string;
  onChange: React.ChangeEventHandler;
  options: SelectableOption[];
  required?: boolean;
  row: boolean;
  value?: string;
};

export function RadioGroup({
  className,
  error,
  errorText,
  label,
  name,
  onChange,
  options,
  required,
  row,
  value,
}: RadioGroupProps) {
  return (
    <FormControl component="fieldset" error={error} className={className}>
      <FormLabel component="legend" required={required}>
        {label}
      </FormLabel>
      <MuiRadioGroup name={name} value={value} onChange={onChange} row={row}>
        {options.map((opt) => (
          <FormControlLabel
            value={opt.value}
            /* although we override the radio button coloring with our styling above, we set the color here
              to get gray for hover styling */
            control={<Radio required={required} color="default" />}
            label={opt.label}
            key={opt.value}
          />
        ))}
      </MuiRadioGroup>
      <ErrorText>{errorText || ' '}</ErrorText>
    </FormControl>
  );
}
