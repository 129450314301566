import { SelectField } from '@monorepo/shared/componentsV2/fields/SelectField';
import { TextField } from '@monorepo/shared/componentsV2/fields/TextField';
import {
  compose,
  isRequired,
  makeIsGreaterThan,
} from '@monorepo/shared/utils/validators';
import * as Sentry from '@sentry/browser';
import _findLast from 'lodash.findlast';
import {
  AggregationInterval,
  AggregationMethod,
  ColumnType,
  QueryOperationType,
  RollingCalculationStep,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import {
  useQuerySteps,
  useQueryStepsForm,
} from '../../../contexts/QueryStepsContext';
import { useSingleQueryStep } from '../../../contexts/SingleQueryStepContext';
import {
  aggregationIntervalLabels,
  aggregationIntervalsInGrowingOrder,
  aggregationMethodLabels,
} from '../consts';
import { InvalidQueryStep } from '../InvalidQueryStep';

type PotentiallyIncompleteRollingCalcStep = Pick<
  RollingCalculationStep,
  'operationType'
> &
  Partial<Omit<RollingCalculationStep, 'operationType'>>;

const EntriesSelectOption = '__entries__' as const;

interface FormValues {
  alias: string;
  columnName: string;
  method: AggregationMethod;
  preceding: string;
  rollingInterval?: AggregationInterval | typeof EntriesSelectOption;
}

const buildInitialFormValues = (
  queryStep: PotentiallyIncompleteRollingCalcStep,
  aggregationInterval?: AggregationInterval,
): Partial<FormValues> => {
  const precedingNumber = queryStep?.operation?.rollingWindow.from;
  const precedingNString =
    precedingNumber != null ? String(-precedingNumber + 1) : undefined;
  return {
    alias: queryStep?.operation?.alias || '',
    columnName: queryStep?.operation?.columnName || '',
    method: queryStep?.operation?.method || AggregationMethod.SUM,
    preceding: precedingNString,
    rollingInterval:
      queryStep?.operation?.rollingWindow.interval ||
      aggregationInterval ||
      EntriesSelectOption,
  };
};

const buildQueryStep = (
  queryStep: RollingCalculationStep,
  values: FormValues,
  dateColumn: string,
): RollingCalculationStep => ({
  ...queryStep,
  operationType: QueryOperationType.ROLLING_CALCULATION,
  operation: {
    alias: values.alias,
    columnName: values.columnName,
    method: values.method,
    rollingWindow: {
      orderBy: dateColumn,
      from: -parseInt(values.preceding, 10) + 1,
      to: 0,
      interval:
        values.rollingInterval === EntriesSelectOption
          ? undefined
          : values.rollingInterval,
    },
  },
});

const rollingMethodOptions = [
  AggregationMethod.SUM,
  AggregationMethod.AVERAGE,
].map((method) => ({
  label: aggregationMethodLabels[method],
  value: method,
}));

const aggregationIntervalOptions = [
  { label: 'Entries', value: EntriesSelectOption },
  ...aggregationIntervalsInGrowingOrder.map((interval) => ({
    label: aggregationIntervalLabels[interval],
    value: interval,
  })),
];

const Container = styled.div`
  width: 100%;
`;

const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const maxInputWidth = `20rem`;

const RollingCalcName = styled(TextField)`
  width: ${maxInputWidth};
  margin-right: 1rem;
`;

const ColumnSelect = styled(SelectField)`
  width: ${maxInputWidth};
`;

const Row2 = styled.div`
  display: grid;
  grid-template-columns: 7rem 10rem 2rem 6rem 8rem;
  column-gap: 1rem;
  align-items: start;
  justify-items: start;
  width: 100%;
`;

const Text = styled.div`
  margin-top: 0.5rem;
`;

const LowercaseText = styled(Text)`
  text-transform: lowercase;
`;

export function RollingCalculationQueryStep() {
  const { availableColumns, dateColumn, index, isLastStep, queryStep } =
    useSingleQueryStep();
  const {
    onQueryStepSubmit,
    registerHandleSubmitForStep,
    setQueryStepsArePristine,
  } = useQueryStepsForm();
  const { validQuerySteps } = useQuerySteps();
  const aggregationInterval = useMemo(() => {
    const previousSteps = validQuerySteps.slice(0, index);
    const lastAggregationStep = _findLast(
      previousSteps,
      (s) => s.operationType === QueryOperationType.AGGREGATION,
    );
    return lastAggregationStep &&
      'groupByInterval' in lastAggregationStep.operation
      ? lastAggregationStep.operation.groupByInterval?.interval
      : undefined;
  }, [index, validQuerySteps]);

  const handleOnSubmit = useCallback(
    async (values: FormValues) => {
      if (!dateColumn) {
        Sentry.captureException(
          `User is trying to submit rolling calculation step without a dateColumn`,
          { extra: { availableColumns } },
        );
        return;
      }
      const step = buildQueryStep(
        queryStep as RollingCalculationStep,
        values,
        dateColumn,
      );
      await onQueryStepSubmit(step, index);
    },
    [availableColumns, dateColumn, index, onQueryStepSubmit, queryStep],
  );

  const columnOptions = useMemo(
    () =>
      availableColumns
        .filter((c) => c.columnType === ColumnType.NUMBER)
        .map((column) => ({
          label: column.columnLabel,
          value: column.columnName,
        })),
    [availableColumns],
  );

  if (!dateColumn || columnOptions.length === 0) {
    const errorMessage = !dateColumn
      ? 'There is no date column to perform a rolling calculation over.'
      : 'There are no numeric columns to perform a rolling calculation on.';

    return <InvalidQueryStep errorMessage={errorMessage} />;
  }

  return (
    <Form
      initialValues={buildInitialFormValues(
        queryStep as PotentiallyIncompleteRollingCalcStep,
        aggregationInterval,
      )}
      onSubmit={handleOnSubmit}
      subscription={{ pristine: true }}
    >
      {({ handleSubmit, pristine }) => {
        registerHandleSubmitForStep(handleSubmit, index);
        setQueryStepsArePristine(pristine);
        return (
          <Container>
            <Row1>
              <RollingCalcName
                disabled={!isLastStep}
                label="Rolling Calculation Name"
                name="alias"
                placeholder="Name your new column"
                required
                validate={isRequired}
              />
              <ColumnSelect
                disabled={!isLastStep}
                label="Column"
                name="columnName"
                options={columnOptions}
                placeholder="Column to calculate on"
                required
                validate={isRequired}
              />
            </Row1>
            <Row2>
              <Text>Perform a rolling</Text>
              <SelectField
                hiddenLabel
                label="Method"
                name="method"
                options={rollingMethodOptions}
                required
                validate={isRequired}
              />
              <Text>over</Text>
              <TextField
                hiddenLabel
                inputProps={{ min: '0' }}
                label="Preceding"
                name="preceding"
                placeholder="n"
                required
                type="number"
                validate={compose(isRequired, makeIsGreaterThan(1))}
              />
              {aggregationInterval ? (
                <LowercaseText>
                  {aggregationIntervalLabels[aggregationInterval]}
                </LowercaseText>
              ) : (
                <SelectField
                  hiddenLabel
                  label="Time Interval or Entries"
                  name="rollingInterval"
                  options={aggregationIntervalOptions}
                  required
                  validate={isRequired}
                />
              )}
            </Row2>
          </Container>
        );
      }}
    </Form>
  );
}
