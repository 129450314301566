import { FormulaForm } from '@monorepo/shared/componentsV2/formula/FormulaForm';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { FormulaStepTokenList } from '@monorepo/workflowDataSetup/src/components/EditWorkflowStepsPage/EditFormulaStepModal/FormulaStepTokenList';
import { WorkflowResponse } from 'mapistry-shared';
import React from 'react';
import { useDatasetSampleFormulaTerms } from '../../../hooks/useDatasetSampleFormulaTerms';
import { findStepInputDataset } from '../stepUtils';

interface EditFormulaStepFormProps {
  workflow: WorkflowResponse;
  stepId?: string;
}

export function EditFormulaStepForm({
  workflow,
  stepId,
}: EditFormulaStepFormProps) {
  const stepInputDataset = findStepInputDataset(workflow, stepId);
  const { datasetSampleFormulaTerms, isLoading } = useDatasetSampleFormulaTerms(
    {
      datasetId: stepInputDataset.id,
      organizationId: workflow.organizationId,
    },
  );
  const { schemaProperties } = stepInputDataset;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <FormulaForm
      sampleFormulaTerms={datasetSampleFormulaTerms}
      tokenList={
        <FormulaStepTokenList stepInputDatasetProperties={schemaProperties} />
      }
    />
  );
}
