import { useQueryStepsForm } from '@monorepo/logs/src/contexts/QueryStepsContext';
import { useSingleQueryStep } from '@monorepo/logs/src/contexts/SingleQueryStepContext';
import { PotentiallyIncompleteQueryStep } from '@monorepo/logs/src/types';
import { Button } from '@monorepo/shared/componentsV2/Button';
import AddIcon from '@svg/add.svg';
import arrayMutators from 'final-form-arrays';
import {
  Aggregation,
  AggregationColumn,
  AggregationMethod,
  AggregationStep,
  BuiltInLogEntryFields,
  GroupByInterval,
  QueryColumn,
  QueryOperationType,
} from 'mapistry-shared';
import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { DeepPartial } from 'utility-types';
import { AggregationColumnDefinition } from './AggregationColumnDefinition';
import { AggregationGroupBySection } from './AggregationGroupBySection';
import { AggregationIntervalSelect } from './AggregationIntervalSelect';
import { SectionHeader, Subtext } from './styled';
import { FormValues } from './types';

const Container = styled.div`
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 2rem;
  column-gap: 1rem;
  align-items: center;
  justify-items: center;
  width: 100%;
`;

const getDefaultAggregationColumnValue = (): Partial<AggregationColumn> => ({
  method: AggregationMethod.SUM,
});

const buildQueryStep = (
  queryStep: PotentiallyIncompleteQueryStep,
  values: FormValues,
  dateColumn: GroupByInterval['columnName'] | undefined,
  availableColumns: QueryColumn[],
): AggregationStep => {
  const countColumn = availableColumns.some(
    (c) => c.columnName === BuiltInLogEntryFields.ID,
  )
    ? BuiltInLogEntryFields.ID
    : 'LOG_ENTRY_COUNT';
  const columns = values.columns.map((ac) => {
    // Use the ID or LOG_ENTRY_COUNT columnName for Count method so that all records are counted
    if (ac.method === AggregationMethod.COUNT) {
      return { ...ac, columnName: countColumn };
    }
    return ac;
  });
  return {
    ...queryStep,
    operationType: QueryOperationType.AGGREGATION,
    operation: {
      columns,
      groupBy: values.groupBy,
      groupByInterval:
        values.interval && dateColumn
          ? { columnName: dateColumn, interval: values.interval }
          : undefined,
    },
  };
};

const buildInitialValues = (
  operation?: Partial<Aggregation>,
): DeepPartial<FormValues> => {
  let columns = operation?.columns || [getDefaultAggregationColumnValue()];
  if (operation?.columns) {
    columns = columns.map((c) => {
      // Count method has no column in the UI
      if (c.method === AggregationMethod.COUNT) {
        return { ...c, columnName: '' };
      }
      return c;
    });
  }
  return {
    columns,
    interval: operation?.groupByInterval?.interval,
    groupBy: operation?.groupBy || [],
  };
};

export function AggregationQueryStep() {
  const { queryStep, isLastStep, index, dateColumn, availableColumns } =
    useSingleQueryStep();
  const {
    onQueryStepSubmit,
    registerHandleSubmitForStep,
    setQueryStepsArePristine,
  } = useQueryStepsForm();
  const aggregationQueryStep = queryStep as Partial<AggregationStep>;

  const onSubmit = useCallback(
    (values: FormValues) =>
      onQueryStepSubmit(
        buildQueryStep(queryStep, values, dateColumn, availableColumns),
        index,
      ),
    [availableColumns, dateColumn, index, onQueryStepSubmit, queryStep],
  );

  return (
    <Form<FormValues, DeepPartial<FormValues>>
      onSubmit={onSubmit}
      subscription={{ submitting: true, pristine: true }}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={buildInitialValues(aggregationQueryStep.operation)}
    >
      {({ handleSubmit, pristine }) => {
        if (isLastStep) {
          // nothing on an aggregation step can be changed if it's not the last step, so don't even register the handleSubmit function
          registerHandleSubmitForStep(handleSubmit, index);
          setQueryStepsArePristine(pristine);
        }
        return (
          <Container>
            <SectionHeader>Aggregate By</SectionHeader>
            <Subtext>
              All aggregations in this step will happen for the chosen time
              interval.
            </Subtext>
            <AggregationIntervalSelect />
            <FieldArray name="columns">
              {({ fields: formFields }) => (
                <>
                  <Grid>
                    {formFields.map((formFieldName, formFieldIndex) => (
                      <AggregationColumnDefinition
                        formFieldName={formFieldName}
                        key={formFieldName}
                        onDelete={
                          isLastStep &&
                          !!formFields.length &&
                          formFields.length > 1
                            ? () => formFields.remove(formFieldIndex)
                            : undefined
                        }
                        showLabel={formFieldIndex === 0}
                      />
                    ))}
                  </Grid>
                  {isLastStep && (
                    <Button
                      color="primary"
                      variant="text"
                      onClick={() =>
                        formFields.push(getDefaultAggregationColumnValue())
                      }
                    >
                      <AddIcon />
                      Add aggregation
                    </Button>
                  )}
                </>
              )}
            </FieldArray>
            <AggregationGroupBySection />
          </Container>
        );
      }}
    </Form>
  );
}
