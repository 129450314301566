import { StyledInput } from '@monorepo/shared/componentsV2/styled/form';
import { usePrevious } from '@monorepo/shared/hooks/usePrevious';
import { isRequired } from '@monorepo/shared/utils/validators';
import { DatasetRefResponse } from 'mapistry-shared';
import React, { useEffect } from 'react';
import { useField } from 'react-final-form';

interface JoinToPrefixInputProps {
  datasets: DatasetRefResponse[];
}

export function JoinToPrefixInput({ datasets }: JoinToPrefixInputProps) {
  const { input } = useField<string>('joinToPrefix');
  const { input: joinToDatasetIdInput } = useField<string>('joinToDatasetId');

  const selectedJoinToDatasetId = joinToDatasetIdInput.value;
  const selectedDatasetName = datasets?.find(
    (d) => d.id === selectedJoinToDatasetId,
  )?.name;

  const previousSelectedJoinToDatasetId = usePrevious(selectedJoinToDatasetId);

  useEffect(
    () => {
      // when changing the joinToDataset, default the prefix to the dataset's name
      // but don't overwrite initialValues on first load
      if (
        (previousSelectedJoinToDatasetId &&
          previousSelectedJoinToDatasetId !== selectedJoinToDatasetId) ||
        !input.value
      ) {
        input.onChange(selectedDatasetName);
      }
    },
    // if I include `input` in the dependency array, it reruns too often and doesn't allow manual editing
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      previousSelectedJoinToDatasetId,
      selectedDatasetName,
      selectedJoinToDatasetId,
    ],
  );

  return (
    <StyledInput
      disabled={!selectedJoinToDatasetId}
      label="Custom Name"
      name="joinToPrefix"
      required
      tooltipText="A custom name can be specified when the data source's name is not concise or sufficiently descriptive. This custom name will be used as a prefix when the properties in this data source are referenced in other Workflow steps, and in Views."
      validate={isRequired}
    />
  );
}
