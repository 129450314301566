import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Error404Page } from '@monorepo/shared/componentsV2/errorDisplays/Error404Page';
import { RoutesWith404 } from '@monorepo/shared/componentsV2/RoutesWith404';
import { useFeatureFlags } from 'mapistry-shared/api';
import React from 'react';
import { Navigate, Route } from 'react-router-dom-v5-compat';
import { LogEntriesTab } from './LogEntriesTab';
import { LogFrequencyRequirementsTab } from './LogFrequencyRequirements';
import { LogsAndViewsPage } from './LogsAndViewsPage';
import { LogsPageContent } from './LogsPage';
import { SingleLogPage } from './SingleLogPage';
import {
  EditViewPageFromLogViews,
  EditViewPageFromProjectViews,
} from './Views/EditViewPage';
import { LogViewsTab } from './Views/LogViewsTab';
import { ProjectViewsPageContent } from './Views/ProjectViewsPage/ProjectViewsPageContent';
import { SingleViewPage } from './Views/SingleViewPage';
import { ViewDetailTab } from './Views/ViewDetailTab';

export function Logs() {
  const { isData2Enabled, areWorkflowsEnabled } = useFeatureFlags();

  return (
    <RoutesWith404>
      <Route path="" element={<LogsAndViewsPage />}>
        <Route index element={<LogsPageContent />} />
        {areWorkflowsEnabled ? (
          <Route path="views">
            <Route index element={<ProjectViewsPageContent />} />
            <Route path="new" element={<EditViewPageFromProjectViews />} />
            <Route path=":viewId" element={<SingleViewPage />}>
              <Route index element={<Navigate replace to="pages" />} />
              <Route path="pages">
                <Route index element={<Navigate replace to="0" />} />
                <Route path=":pageNumber">
                  <Route index element={<ViewDetailTab />} />
                </Route>
              </Route>
              <Route path="edit" element={<EditViewPageFromProjectViews />} />
            </Route>
          </Route>
        ) : (
          // not excluding the entire route because otherwise 'view' gets mistaken as a :logId and we get backend errors
          <Route path="views/*" element={<Error404Page />} />
        )}
      </Route>
      {isData2Enabled && (
        <Route
          path=":logId"
          element={
            <ErrorBoundary>
              <SingleLogPage />
            </ErrorBoundary>
          }
        >
          <Route index element={<Navigate replace to="records" />} />
          <Route path="records">
            <Route index element={<Navigate replace to="pages" />} />
            <Route path="pages">
              <Route index element={<Navigate replace to="0" />} />
              <Route path=":pageNumber">
                <Route index element={<LogEntriesTab />} />
              </Route>
            </Route>
          </Route>
          <Route path="views">
            <Route index element={<LogViewsTab />} />
            <Route path="new" element={<EditViewPageFromLogViews />} />
            <Route path=":viewId">
              <Route index element={<Navigate replace to="pages" />} />
              <Route path="pages">
                <Route index element={<Navigate replace to="0" />} />
                <Route path=":pageNumber">
                  <Route index element={<ViewDetailTab />} />
                </Route>
              </Route>
              <Route path="edit" element={<EditViewPageFromLogViews />} />
            </Route>
          </Route>
          <Route
            path="frequencySettings"
            element={<LogFrequencyRequirementsTab />}
          />
        </Route>
      )}
    </RoutesWith404>
  );
}
