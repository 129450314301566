import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { label } from '@monorepo/shared/styles/text';
import styled from 'styled-components';

export const StyledH3 = styled.h3`
  margin-top: 0;
`;

export const StyledSubheading = styled.div`
  ${label}
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
`;

export const StyledJoinToText = styled.div`
  margin: 1rem 0;
`;

export const StyledConditionsContainer = styled.div`
  max-width: 45rem;
  margin-top: 2.5rem;
`;

export const StyledEqualsText = styled.div<{
  $shouldLabelShow: boolean;
}>`
  margin-top: ${({ $shouldLabelShow }) =>
    $shouldLabelShow
      ? '1.7rem'
      : '0.6rem'}; /* lines this up vertically with the input elements to its left */
`;

export const StyledStepDeleteButton = styled(IconButton)<{
  $shouldLabelShow: boolean;
}>`
  min-width: 2rem;
  margin-top: ${({ $shouldLabelShow }) =>
    $shouldLabelShow
      ? '1.2rem'
      : '0'}; /* lines this up vertically with the input elements to its left */

  font-size: 0.75rem;
`;

export const StyledConditionsDescription = styled.div`
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.gray666};
`;
