import {
  FormulaTokenList,
  FormulaTokenListItem,
  FormulaTokenListItemProp,
  FormulaTokenListItemReactElement,
} from '@monorepo/shared/componentsV2/formula';
import HashIcon from '@svg/hash.svg';
import TextIcon from '@svg/text.svg';
import ToggleIcon from '@svg/toggle.svg';
import {
  DatasetPropertyResponse,
  DatasetPropertyType,
  MathUnit,
} from 'mapistry-shared';
import React, { useMemo } from 'react';

const propertyTypesAvailableForFormula = [
  DatasetPropertyType.BOOLEAN,
  DatasetPropertyType.NUMERIC,
  DatasetPropertyType.TEXT,
];

const datasetPropertyIcons: Partial<
  Record<DatasetPropertyType, React.ReactElement>
> = {
  [DatasetPropertyType.BOOLEAN]: <ToggleIcon />,
  [DatasetPropertyType.NUMERIC]: <HashIcon />,
  [DatasetPropertyType.TEXT]: <TextIcon />,
};

const datasetPropertyIconAltTexts: Partial<
  Record<DatasetPropertyType, string>
> = {
  [DatasetPropertyType.BOOLEAN]: 'Boolean',
  [DatasetPropertyType.NUMERIC]: 'Number',
  [DatasetPropertyType.TEXT]: 'Text',
};

interface FormulaStepTokenListProps {
  stepInputDatasetProperties: DatasetPropertyResponse[];
}

export function FormulaStepTokenList({
  stepInputDatasetProperties,
}: FormulaStepTokenListProps) {
  const tokenListItems = useMemo<FormulaTokenListItemProp[]>(() => {
    const rootItems = {
      header: '',
      items: [] as FormulaTokenListItemReactElement[],
    };
    const rootRecordProperties = stepInputDatasetProperties.filter(
      (p) => p.type === DatasetPropertyType.RECORD && !p.parentPropertyId,
    );
    const recordPropertyIdToItemsDict = rootRecordProperties.reduce(
      (acc, p) => ({
        ...acc,
        [p.id]: {
          header: p.name,
          items: [] as FormulaTokenListItemReactElement[],
        },
      }),
      {} as Record<string, FormulaTokenListItemProp>,
    );

    // Assuming that schema properties are ordered
    stepInputDatasetProperties.forEach((p) => {
      // todo@workflows filter out step ids
      if (!propertyTypesAvailableForFormula.includes(p.type)) {
        return;
      }

      const label =
        p.type === DatasetPropertyType.NUMERIC && p.units
          ? `${p.fullName} (${MathUnit.getLabel(p.units)})`
          : p.fullName;
      const item = (
        <FormulaTokenListItem
          key={p.fullToken}
          icon={datasetPropertyIcons[p.type]}
          iconAltText={datasetPropertyIconAltTexts[p.type]}
          name={label}
          token={p.fullToken}
        />
      );

      const rootParentId = p.parentPropertyIdChain[0];
      const recordPropertyItems = rootParentId
        ? recordPropertyIdToItemsDict[rootParentId]
        : undefined;
      if (recordPropertyItems) {
        recordPropertyItems.items.push(item);
      } else {
        rootItems.items.push(item);
      }
    });

    return [rootItems, ...Object.values(recordPropertyIdToItemsDict)];
  }, [stepInputDatasetProperties]);

  return <FormulaTokenList listItems={tokenListItems} />;
}
