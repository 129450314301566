import { cardBorder } from '@monorepo/shared/styles/card';
import LockIcon from '@svg/lock.svg';
import { DatasetRefResponse } from 'mapistry-shared';
import React from 'react';
import styled from 'styled-components';
import { DatasetChip } from '../DatasetChip';

interface DatasetInfoProps {
  className?: string;
  dataset: DatasetRefResponse;
  isLocked?: boolean;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 35rem;
  padding: 1.2rem 1rem 1rem;
  background-color: ${({ theme }) => theme.colors.grayeee};
  ${cardBorder}
`;

export function DatasetInfo({
  className,
  dataset,
  isLocked = false,
}: DatasetInfoProps) {
  return (
    <Container className={className}>
      <DatasetChip name={dataset.name} />
      {isLocked && <LockIcon />}
    </Container>
  );
}
