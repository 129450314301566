import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import JoinIcon from '@svg/join.svg';
import arrayMutators from 'final-form-arrays';
import {
  CreateWorkflowJoinStepRequest,
  WorkflowStepOperationType,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { DeepPartial } from 'utility-types';
import {
  BaseEditStepModal,
  CommonEditStepModalProps,
} from '../BaseEditStepModal';
import { EditJoinStepForm } from './EditJoinStepForm';

type FormValues = Pick<
  CreateWorkflowJoinStepRequest['operationConfig'],
  'conditions' | 'joinToDatasetId' | 'joinToPrefix'
>;

export function EditJoinStepModal({
  onSubmitStep,
  stepId,
  workflow,
  ...props
}: CommonEditStepModalProps<CreateWorkflowJoinStepRequest>) {
  const submitStep = useCallback(
    async (data: FormValues) => {
      const step: CreateWorkflowJoinStepRequest = {
        operationConfig: data,
        type: WorkflowStepOperationType.JOIN,
      };
      await onSubmitStep(step);
    },
    [onSubmitStep],
  );

  const initialValues = useMemo<DeepPartial<FormValues>>(() => {
    if (!stepId) {
      const emptyFormWithOneCondition: DeepPartial<FormValues> = {
        conditions: [{ leftPropertyId: undefined, rightPropertyId: undefined }],
      };
      return emptyFormWithOneCondition;
    }

    const step = workflow.steps.find((s) => s.id === stepId);
    if (!step || step.type !== WorkflowStepOperationType.JOIN) {
      throw new Error(
        `EditJoinStepModal was opened to edit not join step. Type: ${step?.type}, step id: ${stepId}.`,
      );
    }
    const filteredConditions = step.operationConfig.conditions.filter(
      (condition) => !condition.readOnly,
    );
    // Defining this variable to get misspelling check. TS allows specify only known properties.
    const savedValues: DeepPartial<FormValues> = {
      conditions: filteredConditions,
      joinToDatasetId: step.operationConfig.joinToDataset.id,
      joinToPrefix: step.operationConfig.joinToPrefix,
    };
    return savedValues;
  }, [stepId, workflow]);

  return (
    <Form<FormValues, DeepPartial<FormValues>>
      onSubmit={submitStep}
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      subscription={{ invalid: true, pristine: true, submitting: true }}
    >
      {(formProps) => (
        <BaseEditStepModal
          {...props}
          {...formProps}
          title="Connect"
          icon={<JoinIcon />}
        >
          <ErrorBoundary>
            <EditJoinStepForm stepId={stepId} workflow={workflow} />
          </ErrorBoundary>
        </BaseEditStepModal>
      )}
    </Form>
  );
}
