import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useDatasets } from '@monorepo/shared/hooks/workflows/useDatasets';
import { DatasetResponse } from 'mapistry-shared';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DatasetPicker } from '../../shared/DatasetPicker/DatasetPicker';
import { EmptyDatasetList } from '../../shared/DatasetPicker/EmptyDatasetList';
import { JoinToPrefixInput } from './JoinToPrefixInput';

interface JoinToDatasetPickerProps {
  organizationId: string;
  workflowOutputDataset: DatasetResponse;
  stepInputDataset: DatasetResponse;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledJoinToPrefixInput = styled(JoinToPrefixInput)`
  width: 35rem;
`;

// todo@workflows: probably disable this for editing purposes
export function JoinToDatasetPicker({
  organizationId,
  stepInputDataset,
  workflowOutputDataset,
}: JoinToDatasetPickerProps) {
  const { datasets, isFetching } = useDatasets({
    organizationId,
  });

  const filteredDatasets = useMemo(() => {
    if (!datasets) {
      return [];
    }

    return datasets.filter(
      (d) => d.id !== stepInputDataset.id && d.id !== workflowOutputDataset.id,
    );
  }, [datasets, stepInputDataset.id, workflowOutputDataset.id]);

  if (isFetching) {
    return <Loading size="medium" />;
  }

  if (!filteredDatasets.length) {
    return <EmptyDatasetList />;
  }

  return (
    <Container>
      <DatasetPicker
        datasets={filteredDatasets}
        fieldName="joinToDatasetId"
        label="Data Source 2"
        required
      />
      <StyledJoinToPrefixInput datasets={filteredDatasets} />
    </Container>
  );
}
